import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { CarouselRef } from "antd/es/carousel";
import {
  Button,
  Carousel,
  Col,
  Grid,
  Input,
  message,
  ModalProps,
  Row,
  Select,
  Typography,
} from "antd";

import Flex from "layouts/Flex";
import CustomModal from "layouts/CustomModal";

import NewVideoGif from "assets/gifs/new-video.gif";
import { ReactComponent as DoubleArrowIconDisabled } from "assets/svgs/double-arrow-icon.svg";
import { ReactComponent as DoubleArrowIconActive } from "assets/svgs/double-arrow-icon-purple.svg";
import { ReactComponent as CheckmarkIcon } from "assets/svgs/checkmark.svg";
import { ReactComponent as CheckmarkSelectedIcon } from "assets/svgs/checkmark-selected.svg";
import { submitReport } from "api/api";
import VideoPlayer from "components/others/VideoPlayer";

const { useBreakpoint } = Grid;

interface ReportFakeModalProps extends ModalProps {
  data: any;
  onDone: () => void;
}

export default function ReportFakeModal({
  open,
  data,
  onDone,
  onCancel,
}: ReportFakeModalProps) {
  const classes = useStyle();
  const isMD = useBreakpoint().md;

  const CarouselRef = useRef<CarouselRef>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [fakeRank, setFakeRank] = useState<number | null>(null);
  const [fakeType, setFakeType] = useState<string | null>(null);
  const [description, setDescription] = useState("");
  const [enableNext, setEnableNext] = useState(false);
  const [hideBackButton, setHideBackButton] = useState(false)
  const [shouldAutoPlay, setShouldAutoPlay] = useState(true)
  const [reporterDetails, setReporterDetails] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    social_platform: undefined,
    handle: "",
  });

  const videoPlayerRef = useRef(null)

  useEffect(() => {
    // @ts-ignore
    setModalOpen(open);
  }, [open]);

  useEffect(() => {
    switch (currentStep) {
      case 1:
        if (!!fakeType) setEnableNext(true);
        break;
      case 2:
        if (!!fakeRank) setEnableNext(true);
        break;

      default:
        setEnableNext(true);
        break;
    }
  }, [currentStep, fakeType, fakeRank]);

  const handleReporterDetailsChange = (label: string) => (e: any) => {
    setReporterDetails((curr: any) => {
      curr[label] = e?.target?.value ?? e;
      return { ...curr };
    });
  };

  const handleSubmitData = async () => {

    let dataToSubmit = {
      ...data,
      type: fakeType,
      reporter_details: {},
    };

    if (!!fakeRank) {
      dataToSubmit.confidence_level = fakeRank;
    }

    if (description.length > 0) {
      dataToSubmit.description = description;
    }

    Object.keys(reporterDetails).forEach((key) => {
      // @ts-ignore
      if (!!reporterDetails[key] && reporterDetails[key] !== "") {
        // @ts-ignore
        dataToSubmit.reporter_details[key] = reporterDetails[key];
      }
    });

    console.log("submitting data")
    console.log(dataToSubmit)

    let res = await submitReport(dataToSubmit);

    console.log("res");
    console.log(res);
    
    
    if (!!res?._id && currentStep === 5) {
      message.success("Reported sucessfully!");
    }
  };

  useEffect(() => {
    if (currentStep !== 6) {
      resetPlayer()
    } else {
      const player = videoPlayerRef.current
      if (player && shouldAutoPlay) {
        setShouldAutoPlay(false)
        // @ts-ignore
        player.seek(0);
        // @ts-ignore
        player.play();
      }
    }
  }, [currentStep])

  const handleNext = () => {
    setEnableNext(false);

    // change this later
    if (currentStep === 6) {
      setModalOpen(false);
      resetPlayer();
      setCurrentStep(1);
      CarouselRef.current?.goTo(0);
      onDone();
      window.open(`${process.env.REACT_APP_API_HOST}/home/?utm_source=stopcatfish&utm_medium=web&utm_campaign=promo&utm_content=report_submitted_take_me_there`, '_blank')
      return;
    }

    if (currentStep === 5) handleSubmitData();
    
    CarouselRef.current?.next();
    if (currentStep < 6) setCurrentStep(currentStep + 1);
  };

  const resetPlayer = () => {
    const player = videoPlayerRef.current
    if (player) {
      // @ts-ignore
      player.seek(0);
      // @ts-ignore
      player.stop();
    }
  }

  const handleBack = () => {
    if (currentStep === 1) {
      setModalOpen(false);
      // @ts-ignore
      onCancel();
      resetPlayer();
    } else {
      CarouselRef.current?.prev();
      if (currentStep > 1) setCurrentStep(currentStep - 1);
    }
  };

  const handleCancel = () => {
    CarouselRef.current?.goTo(0);
    setCurrentStep(1)
    setModalOpen(false)
    // @ts-ignore
    onCancel();
    resetPlayer();
  }

  useEffect(() => {
    console.log(`current step: ${currentStep}`);
    if (currentStep === 6) {
      setHideBackButton(true)
    } else {
      setHideBackButton(false)
    }
    
  }, [currentStep])

  const formatText = (text: string) => text.toLowerCase().split(" ").join("_");

  return (
    <CustomModal
      open={modalOpen}
      onBack={handleBack}
      onCancel={handleCancel}
      hideBackBtn={hideBackButton}
    >
      <Carousel
        swipe={false}
        dots={false}
        infinite={false}
        accessibility={false}
        swipeToSlide={false}
        ref={CarouselRef}
      >
        {/* What Kind Of Fake? */}
        <Flex justify="center" align="center" direction="column">
          <Typography.Text className={classes.continueToLinkTxt}>
            What Kind of Fake?
          </Typography.Text>

          <Flex justify="center" align="center" direction="column">
            {["SCAMMER", "FAKE ACCOUNT", "IMPERSONATOR"].map((txt, index) => (
              <Button
                size="large"
                shape="round"
                key={index}
                className={classes.fakeKindOption}
                onClick={() => setFakeType(formatText(txt))}
                style={
                  fakeType === formatText(txt)
                    ? {
                        color: "#0958d9",
                        borderColor: "#0958d9",
                      }
                    : {}
                }
              >
                {txt}
              </Button>
            ))}
          </Flex>

          <Typography.Text className={classes.bottomText}>
            I confirm the
            <strong> link </strong>
            provided
            <br />
            matches the label above.
          </Typography.Text>
        </Flex>

        {/* Rank This Fake */}
        <Flex justify="center" align="center" direction="column">
          <Typography.Text className={classes.rankThisFakeTxt}>
            Rank This Fake
          </Typography.Text>
          <Typography.Text className={classes.subTitle}>
            How confident are you?
          </Typography.Text>

          <Flex justify="space-between" align="center">
            {[
              { label: "Possibly Fake", rank: 3 },
              { label: "Likely Fake", rank: 2 },
              { label: "Definitely Fake", rank: 1 },
            ].map((txt, index) => (
              <Flex
                align="center"
                justify="center"
                direction="column"
                key={index}
                className={classes.rankFakeOption}
                onClick={() => setFakeRank(txt.rank)}
              >
                <Typography.Text className={classes.rankFakeOptionText}>
                  {txt.label}
                </Typography.Text>
                {txt.rank === fakeRank ? (
                  <CheckmarkSelectedIcon width={isMD ? 40 : 30} />
                ) : (
                  <CheckmarkIcon width={isMD ? 40 : 30} />
                )}
              </Flex>
            ))}
          </Flex>

          <Typography.Text className={classes.bottomText}>
            I am making my best, honest, and
            <br />
            accurate judgment, so I feel this is TRUE.
          </Typography.Text>
        </Flex>

        {/* Incident Report */}
        <Flex justify="center" align="center" direction="column">
          <Typography.Text className={classes.rankThisFakeTxt}>
            Incident Report
          </Typography.Text>

          <Flex justify="center" className={classes.incidentReportInputWrapper}>
            <Input.TextArea
              rows={5}
              value={description}
              placeholder="(Optional) - Please include details about your incident."
              className={classes.incidentReportInput}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Flex>

          <Typography.Text className={classes.bottomText}>
            My statement is true and accurate.
            <br />
            Information provided may be shared for awareness,
            <br />
            <b> personal info </b>
            will <u>NOT</u> be shared.
          </Typography.Text>
        </Flex>
        {/* Your Details - I */}
        <Flex justify="center" align="center" direction="column">
          <Typography.Text className={classes.rankThisFakeTxt}>
            Your Details
          </Typography.Text>
          <Typography.Text className={classes.subTitle2}>
            Social Valid may be reach out to you and to
            <br />
            gather more information.
          </Typography.Text>

          <Flex justify="center" className={classes.detailsInputWrapper}>
            <Col span={20}>
              <Row gutter={[20, 20]}>
                <Col span={12}>
                  <Input
                    placeholder="First name"
                    className={classes.detailsInput}
                    onChange={handleReporterDetailsChange("first_name")}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    placeholder="Last name"
                    className={classes.detailsInput}
                    onChange={handleReporterDetailsChange("last_name")}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    type="tel"
                    placeholder="Phone"
                    className={classes.detailsInput}
                    onChange={handleReporterDetailsChange("phone")}
                  />
                </Col>
              </Row>
            </Col>
          </Flex>

          <Typography.Text className={classes.bottomText}>
            I agree to the Terms and Conditions and
            <br />
            Privacy Policy of Social Valid
          </Typography.Text>
        </Flex>

        {/* Your Details - II */}
        <Flex justify="center" align="center" direction="column">
          <Typography.Text className={classes.rankThisFakeTxt}>
            Your Details
          </Typography.Text>
          <Typography.Text className={classes.subTitle2}>
            Social Valid may be reach out to you and to
            <br />
            gather more information.
          </Typography.Text>

          <Flex justify="center" className={classes.detailsInputWrapper}>
            <Col span={20}>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <Input
                    type="email"
                    placeholder="Your email"
                    className={classes.detailsInput}
                    onChange={handleReporterDetailsChange("email")}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    prefix="@"
                    placeholder="Handle"
                    className={classes.detailsInput}
                    onChange={handleReporterDetailsChange("handle")}
                  />
                </Col>
                <Col span={12}>
                  <Select
                    placeholder="Platform"
                    className={classes.detailsSelect}
                    onChange={handleReporterDetailsChange("social_platform")}
                    options={[
                      { label: "Instagram", value: "instagram" },
                      { label: "Facebook", value: "facebook" },
                      { label: "TikTok", value: "tiktok" },
                      { label: "Twitter", value: "twitter" },
                      { label: "Linkedin", value: "linkedin" },
                      { label: "YouTube", value: "youtube" },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          </Flex>

          <Typography.Text className={classes.bottomText}>
            I agree to the Terms and Conditions and
            <br />
            Privacy Policy of Social Valid
          </Typography.Text>
        </Flex>

        {/* Report Filed */}
        <Flex justify="center" align="center" direction="column">
          <Typography.Text className={classes.rankThisFakeTxt}>
            Report Filed
          </Typography.Text>
          <Typography.Text className={classes.subTitle2}>
            Support Social Valid's mission of making the
            <br />
            web a safer place.
          </Typography.Text>

          <Flex justify="center" align="center" style={{ width: "100%" }}>
            {/* <img src={NewVideoGif} style={{ width: "70%" }} alt="video-gif" /> */}
            <VideoPlayer 
              videoUrl="https://cdn.jwplayer.com/manifests/8SVc4A1j.m3u8"
              playerRef={videoPlayerRef}
            />
          </Flex>
        </Flex>
      </Carousel>

      <Flex justify="center" direction="column" align="center">
        <Button
          type="text"
          disabled={!enableNext}
          className={classes.nextBtn}
          icon={
            enableNext ? (
              currentStep === 5 ? <CheckmarkSelectedIcon width={70} /> : <DoubleArrowIconActive width={70} />
            ) : (
              <DoubleArrowIconDisabled width={70} />
            )
          }
          onClick={handleNext}
        />
        {currentStep === 5 ? (
          <Typography.Text className={classes.btnSupportText}>
            Submit Report
          </Typography.Text>
        ) : (
          <></>
        )}
        {currentStep === 6 ? (
          <Typography.Text className={classes.btnSupportText}>
            Take me there
          </Typography.Text>
        ) : (
          <></>
        )}
      </Flex>
    </CustomModal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  "@global": {
    ".ant-select-item-option-content": {
      color: "#010101 !important",
    },
    ".ant-btn.ant-btn-icon-only": {
      width: "auto !important",
    },
  },
  continueToLinkTxt: {
    lineHeight: "50px",
    fontSize: "30pt",
    textAlign: "center",
    fontFamily: "Arimo",
    fontWeight: 400,
    color: "#010101",
    display: "block",
    margin: [20, 0],
  },
  rankThisFakeTxt: {
    lineHeight: "50px",
    fontSize: "30pt",
    textAlign: "center",
    fontFamily: "Arimo",
    fontWeight: 400,
    color: "#010101",
    display: "block",
    margin: [20, 0, 0],
  },
  subTitle: {
    fontSize: "17pt",
    fontFamily: "Yaldevi",
    fontWeight: 400,
    color: "#000000",
    display: "block",
    marginBottom: 20,
  },
  subTitle2: {
    textAlign: "center",
    fontSize: "12pt",
    fontFamily: "Yaldevi",
    fontWeight: 400,
    color: "#000000",
    display: "block",
    marginBottom: 20,
  },
  reportedAccLinkTxt: {
    fontSize: "18pt",
    fontFamily: "Open Sans",
    fontWeight: 300,
    color: "#6271C5",
    display: "block",
    margin: [20, 0, 40],
  },
  bottomText: {
    fontSize: "12pt",
    fontFamily: "Yaldevi",
    fontWeight: 400,
    color: "#010101",
    textAlign: "center",
    display: "block",
    marginTop: 10,
  },
  fakeKindOption: {
    height: 45,
    width: 220,
    marginBottom: 10,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#545454",
    fontFamily: "Arimo",
    fontWeight: 700,
    fontSize: "15pt",
    letterSpacing: 2,

    "&:nth-child(odd)": {
      background:
        "linear-gradient(270deg, rgba(255,189,255,1) 0%, rgba(126,246,222,1) 85%)",
    },
    "&:nth-child(even)": {
      background:
        "linear-gradient(90deg, rgba(255,189,255,1) 0%, rgba(126,246,222,1) 85%)",
    },
  },
  nextBtn: {
    marginTop: 10,
    minHeight: 80,
    minWidth: 80,
    "& svg": {
      width: 70,
      height: 70,
    },
  },
  btnSupportText: {
    fontFamily: "Yaldevi",
    fontWeight: 400,
    fontSize: "12pt",
    color: "#010101",
    display: "block",
    marginTop: 10,
  },

  rankFakeOption: {
    padding: [0, 20],
    marginBottom: 20,
    textAlign: "center",
  },
  rankFakeOptionText: {
    fontFamily: "Yaldevi",
    fontWeight: 400,
    fontSize: "18pt",
    color: "#010101",
    lineHeight: "35px",
    marginBottom: 10,
    display: "block",
  },

  incidentReportInputWrapper: {
    marginTop: 10,
    width: "100%",
  },
  incidentReportInput: {
    width: "90%",
    padding: 15,
    fontFamily: "Yaldevi",
    fontWeight: 400,
    fontSize: "14pt",
    color: "#010101",
    border: "none",
    borderRadius: 20,
  },

  detailsInputWrapper: {
    marginBottom: 25,
  },
  detailsInput: {
    "&.ant-input-affix-wrapper, &.ant-input, & .ant-input": {
      fontFamily: "Yaldevi",
      fontWeight: 400,
      fontSize: "14pt",
      background: "transparent",
      color: "#010101",
      border: "none",
      boxShadow: "none",
      borderRadius: 0,
      borderBottom: "1px solid #A6A6A6",
    },
  },
  detailsSelect: {
    width: "100%",
    height: "100%",
    "& .ant-select-selector": {
      height: "100% !important",
      backgroundColor: "transparent !important",
      border: "none !important",
      borderRadius: 0,
      borderBottom: "1px solid #A6A6A6 !important",
    },
    "& input, & .ant-select-selector": {
      fontFamily: "Yaldevi",
      fontWeight: 400,
      fontSize: "14pt",
      color: "#010101",
    },
  },

  "@media (max-width: 500px)": {
    rankFakeOptionText: {
      fontSize: "14pt",
      lineHeight: "25px",
      marginTop: 10,
    },
  },
}));
