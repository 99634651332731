import { useEffect, useRef } from "react";

interface VideoPlayerProps {
    videoUrl: string,
    playerRef: any
}

export default function VideoPlayer({ videoUrl, playerRef }: VideoPlayerProps) {
    const videoPlayerRef = useRef(null)

    useEffect(() => {
        // @ts-ignore
        const player = window.jwplayer(videoPlayerRef.current);
        player.setup({
            file: videoUrl,
            width: "100%",
            aspectratio: "16:9",
            autostart: "viewable",
        })

        playerRef.current = player

        player.setVolume(10);

        return () => {
            player.remove();
        };
    }, [videoUrl]);

    return (
        <div id="video-player" ref={videoPlayerRef} />
    );
}