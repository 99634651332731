import axios, { AxiosRequestConfig } from "axios";
const Host = process.env.REACT_APP_API_HOST;

export const Token = async () => {
  let token = localStorage.getItem("token");
  let endpoint = `/api/v1/signin`;
  if (token) {
    const headers: AxiosRequestConfig = {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }
    token = await axios.get(Host + endpoint, headers).then((res) => res.data.token);
  } else {
    token = await axios.get(Host + endpoint, {}).then((res) => res.data.token);
  }
  if (token) localStorage.setItem("token", token); 
  return token;
};

export const searchLinks = async (usernameInput: string, signal?: AbortSignal) => {
  const encodedUsernameInput = encodeURIComponent(usernameInput).replace(/\./g, "%2E");
  // console.log(`search term: ${encodedUsernameInput}`)
  let endpoint = `/api/v1/links/report/search?search=${encodedUsernameInput}&offset=0&limit=8`;
  const config = {
    headers: {
      Authorization: `Bearer ${await Token()}`,
    },
    signal: signal,
  };
  return await axios.get(Host + endpoint, config).then((res) => [res.data, usernameInput]).catch(e => [e.response.status, usernameInput]);
};


export const submitReport = async (data: string) => {
  let endpoint = `/api/v1/links/report/submit`;
  return await axios
    .post(Host + endpoint, data, {
      headers: {
        Authorization: `Bearer ${await Token()}`,
      },
    })
    .then((res) => res.data);
};
