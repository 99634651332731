import { Button, Modal, ModalProps } from "antd";
import { createUseStyles } from "react-jss";

import PurpleArrowIcon from "assets/pngs/purple-arrow.png";
import X from "assets/svgs/x.svg";
import { LeftOutlined } from "@ant-design/icons";

interface CustomModalProps extends ModalProps {
  // ----
  hideBackBtn?: boolean;
  onBack?: any;
}

export default function CustomModal({
  hideBackBtn,
  children,
  ...props
}: CustomModalProps) {
  const classes = useStyle();

  return (
    <Modal
      {...props}
      centered
      footer={false}
      closable={false}
      width={500}
      className={classes.customModal}
    >
      <Button
        type="text"
        hidden={hideBackBtn}
        className={classes.backIcon + (hideBackBtn ? " " + classes.visibilityHidden : "")}
        // @ts-ignore
        onClick={() => props?.onBack()}
        icon={<img src={PurpleArrowIcon} alt="" />}
      />
      <Button
        type="text"
        className={classes.xIcon}
        // @ts-ignore
        onClick={() => props?.onCancel()}
        icon={<img src={X} alt="" />}
      />
      {children}
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  customModal: {
    "& .ant-modal-content": {
      minHeight: 200,
      padding: 15,

      position: "relative",
      backgroundColor: "rgba(255,255,255,0.9)",
    },
  },
  backIcon: {
    zIndex: 9999,
    position: "absolute",
    top: 5,
    left: 5,
    "& img": {
      height: 20,
    },
  },
  xIcon: {
    zIndex: 9999,
    position: "absolute",
    top: 5,
    right: 5,
    "& img": {
      height: 20,
    },
  },
  visibilityHidden: {
    visibility: "hidden",
  }
}));
