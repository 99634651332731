import { createUseStyles } from "react-jss";
import Flex from "layouts/Flex";

import { ReactComponent as PlayIcon } from "assets/svgs/play.svg";
import { ReactComponent as TripleArrowLeftIcon } from "assets/svgs/triple-arrow-left.svg";
import { ReactComponent as TripleArrowRightIcon } from "assets/svgs/triple-arrow-right.svg";

import { useRef, useState } from "react";
import { Button, Space, Grid, Col } from "antd";
import { Socials } from "data/socials";

const { useBreakpoint } = Grid;

interface PlatformBoxProps {
  onSelect: (platform: string) => void;
}

export default function PlatformBox({ onSelect }: PlatformBoxProps) {
  const classes = useStyle();
  const isMD = useBreakpoint().md;
  const SocialIconsScroll = useRef<HTMLDivElement>(null);
  const [showSocialIcons, setShowSocialIcons] = useState(false);

  const toggleSocialIcons = () => setShowSocialIcons(!showSocialIcons);

  const scrollRight = () => {
    if (!!SocialIconsScroll.current) {
      SocialIconsScroll.current.scrollLeft =
        SocialIconsScroll.current.scrollWidth;
    }
  };

  const scrollLeft = () => {
    if (!!SocialIconsScroll.current) {
      SocialIconsScroll.current.scrollLeft = 0;
    }
  };

  if (!isMD) {
    return (
      <Flex
        align="center"
        direction="column"
        className={classes.platformBox}
        style={{}}
      >
        <Flex style={{ padding: 5 }}>
          <PlayIcon className={classes.playIcon} onClick={toggleSocialIcons} />
          <span className={classes.platformBoxText}>
            Report fake accounts directly to the Social Media Platform
          </span>
        </Flex>
        <Col span={24}>
          {showSocialIcons ? (
            <Space size={10} className={classes.socialIconsWrapper}>
              <Button
                type="text"
                icon={<TripleArrowLeftIcon className={classes.socialIcon} />}
                className={classes.socialIcon}
                onClick={scrollLeft}
              />
              <div
                ref={SocialIconsScroll}
                className={classes.socialIconsScroll}
                style={{
                  height: showSocialIcons ? "auto" : "0px",
                  width: "calc(100vw - 170px)",
                }}
              >
                <Flex align="center" className={classes.socialIconsScrollInner}>
                  {Socials.map((s) => (
                    <div
                      key={s.name}
                      onClick={() => {
                        onSelect(s.name);
                        setShowSocialIcons(false);
                      }}
                    >
                      {s.icon({ className: classes.socialIcon })}
                    </div>
                  ))}
                </Flex>
              </div>
              <Button
                type="text"
                icon={<TripleArrowRightIcon className={classes.socialIcon} />}
                className={classes.socialIcon}
                onClick={scrollRight}
              />
            </Space>
          ) : (
            <></>
          )}
        </Col>
      </Flex>
    );
  }

  return (
    <Flex
      align="center"
      className={classes.platformBox}
      style={{
        width: showSocialIcons ? "fit-content" : "30vw",
      }}
    >
      <PlayIcon className={classes.playIcon} onClick={toggleSocialIcons} />
      {showSocialIcons ? (
        <Space size={20} className={classes.socialIconsWrapper}>
          <Button
            type="text"
            icon={<TripleArrowLeftIcon className={classes.socialIcon} />}
            className={classes.socialIcon}
            onClick={scrollLeft}
          />
          <div
            ref={SocialIconsScroll}
            className={classes.socialIconsScroll}
            style={{
              width: showSocialIcons ? "52vw" : "0px",
              // maxWidth: 320,
            }}
          >
            <Flex className={classes.socialIconsScrollInner}>
              {Socials.map((s) => (
                <div
                  key={s.name}
                  onClick={() => {
                    onSelect(s.name);
                    setShowSocialIcons(false);
                  }}
                >
                  {s.icon({ className: classes.socialIcon })}
                </div>
              ))}
            </Flex>
          </div>
          <Button
            type="text"
            icon={<TripleArrowRightIcon className={classes.socialIcon} />}
            className={classes.socialIcon}
            onClick={scrollRight}
          />
        </Space>
      ) : (
        <span className={classes.platformBoxText}>
          Report fake accounts directly to the Social Media Platform
        </span>
      )}
    </Flex>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  platformBox: {
    marginTop: 40,
    minHeight: 60,
    padding: [5, 15, 0],
    borderRadius: 10,
    backgroundColor: "#545454",
    transition: "all 0.2s",
  },
  playIcon: {
    minWidth: 40,
    maxWidth: 40,
    paddingRight: 12,
  },
  platformBoxText: {
    fontSize: "10pt",
    fontFamily: "Yaldevi",
    color: colors.light100,
    display: "block",
    lineHeight: "22px",
  },

  socialIconsWrapper: {
    paddingLeft: 10,
    zIndex: 2,
    borderLeft: "2px solid #ffffff",
  },
  socialIconsScroll: {
    flex: 1,
    overflowX: "auto",
    display: "flex",
    alignItems: "center",
    scrollBehavior: "smooth",
    transition: "all 0.2s",
    transitionDelay: "0.5s",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  socialIconsScrollInner: {
    "& > div": {
      margin: [0, 10],
      "&::first-child": {
        marginLeft: "0 !important",
      },
      "&::last-child": {
        marginRight: "0 !important",
      },
    },
  },
  socialIcon: {
    height: 40,
    width: 40,
  },

  "@media (min-width:500px) and (max-width: 1024px)": {
    platformBox: {
      marginTop: 50,
      position: "static",
    },
  },
  "@media (max-width:500px)": {
    platformBox: {
      marginTop: 30,
      width: "auto !important",
      position: "static",
    },
    socialIconsWrapper: {
      paddingLeft: 0,
      paddingTop: 5,
      paddingBottom: 10,
      zIndex: 2,
      borderLeft: "none",
    },
    playIcon: {
      minWidth: 40,
      maxWidth: 40,
      paddingRight: 12,
    },
    socialIcon: {
      height: 30,
      width: 30,
    },
    socialIconsScrollInner: {
      "& > div": {
        margin: [0, 8],
      },
    },
  },
}));
