import { Button, Col, Row, Typography } from "antd";
import Flex from "layouts/Flex";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { ReactComponent as PlaneIcon } from "assets/svgs/cloud-and-plane.svg";
import { ReactComponent as PlayIcon } from "assets/svgs/play.svg";

import VideoPlaceholder from "assets/pngs/video-placeholder.png";
import SocialValidLogo from "assets/pngs/Powered-by-Social-Valid.png";

import { Socials } from "data/socials";
import VideoPlayer from "./VideoPlayer";

interface ReportSocialProps {
  social: string;
  onClose: () => void;
}

export default function ReportSocial({ social, onClose }: ReportSocialProps) {
  const [socialData, setSocialData] = useState(Socials[0]);
  const [shouldAutoPlay, setShouldAutoPlay] = useState(true);

  const videoPlayerRef = useRef(null);
  const classes = useStyle();

  useEffect(() => {
    const player = videoPlayerRef.current
    if (player) {
      // @ts-ignore
      player.stop();
      // @ts-ignore
      player.seek(0);
    }
    if (shouldAutoPlay) {
      setShouldAutoPlay(false)
      // @ts-ignore
      player.seek(0);
      // @ts-ignore
      player.play();
    }
  }, [videoPlayerRef])

  useEffect(() => {
    setSocialData(Socials.find((i) => i.name === social) ?? Socials[0]);
  }, [social]);

  return (
    <div className={classes.homePage}>
      <Flex align="center" className={classes.header}>
        <span className={classes.headerText}>
          CATFISH & FAKE
          <br /> ACCOUNT DATABASE
        </span>

        <PlaneIcon className={classes.planeIcon} />
      </Flex>

      <Row style={{ padding: "20px 0 0", position: "relative" }}>
        <Col xs={24} md={12} className={classes.pageLeft}>
          <Flex
            direction="column"
            justify="space-around"
            style={{ height: "100%" }}
          >
            <div>
              <div>{socialData?.icon({ className: classes.socialIcon })}</div>

              <div className={classes.title}>
                Report a Fake Account on {socialData?.name}
              </div>

              <div className={classes.subTitle}>
                Report fake accounts directly to the Social Media Platform
              </div>

              <Button
                type="primary"
                shape="round"
                className={classes.reportFakeBtn}
                onClick={() => {
                  window.open(socialData?.url, "_blank")
                }}
              >
                Report Fake
              </Button>
            </div>

            <Flex align="center" onClick={onClose}>
              <div className={classes.searchRealTxt}>
                Search Real & Fake Accounts
              </div>
              <PlayIcon className={classes.playIcon} />
            </Flex>
          </Flex>
        </Col>
        <Col xs={24} md={12} className={classes.pageRight}>
          <Flex
            direction="column"
            justify="space-around"
            className={classes.pageRightInner}
          >
            <Flex style={{ flex: 1, width: "100%" }} align="center">
              {/* <img src={VideoPlaceholder} alt="" width="100%" /> */}
              <VideoPlayer videoUrl="https://cdn.jwplayer.com/manifests/8SVc4A1j.m3u8" playerRef={videoPlayerRef} />
            </Flex>

            <Flex 
              align="center" 
              className={classes.exploreBox}
              onClick={() => {
                window.open(`${process.env.REACT_APP_API_HOST}/home/?utm_source=stopcatfish&utm_medium=web&utm_campaign=promo&utm_content=explore_social_valid`, '_blank');
              }}
            >
              <PlayIcon className={classes.playIcon} />
              <Typography.Text className={classes.exploreTxt}>
                Explore
              </Typography.Text>
            </Flex>
          </Flex>
        </Col>
        <img
          src={SocialValidLogo}
          alt="Powered by Social Valid"
          className={classes.socialValidLogo}
          onClick={() => {
            window.open(`${process.env.REACT_APP_API_HOST}/home/?utm_source=stopcatfish&utm_medium=web&utm_campaign=promo&utm_content=powered_by_social_valid`, '_blank');
          }}
        />
      </Row>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  homePage: {
    padding: [20, 30],

    background: colors.dark1000,
    minHeight: "100vh",
  },
  header: {
    paddingBottom: 20,
    borderBottom: "0.5px solid #f8f8f8",
  },
  headerText: {
    marginRight: 20,
    color: colors.light100,
    // fontSize: "16pt",
    fontFamily: "Arimo",
    fontWeight: 700,
  },
  planeIcon: {
    width: 40,
  },
  pageLeft: {
    borderRight: "0.5px solid #f8f8f8",
    height: "calc(100vh - 150px)",
  },
  pageRight: {
    position: "relative",
  },
  pageRightInner: { height: "100%", padding: "0 70px" },
  reportFakeBtn: {
    width: 160,
    height: 35,

    fontFamily: "Yaldevi",
    fontWeight: 400,
    fontSize: "14pt",
    background: "rgb(240,103,125) !important",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  socialIcon: { height: 120, width: 120 },
  title: {
    marginTop: 30,
    fontFamily: "Arimo",
    fontWeight: 400,
    fontSize: "32pt",
    paddingRight: 20,
    color: colors.light100,
  },
  subTitle: {
    marginTop: 10,
    marginBottom: 30,
    fontFamily: "Yaldevi",
    fontWeight: 400,
    fontSize: "12pt",
    color: colors.light500,
  },
  searchRealTxt: {
    marginRight: 15,
    fontFamily: "Arimo",
    fontWeight: 400,
    fontSize: "16pt",
    color: colors.light100,
  },
  playIcon: {
    width: 40,
  },

  exploreBox: {
    height: 35,
    width: 150,
    padding: [15, 20],
    background: "#424242",
    borderRadius: 15,
  },
  exploreTxt: {
    marginLeft: 15,
    fontFamily: "Arimo",
    fontWeight: 400,
    fontSize: "18pt",
    color: colors.light100,
  },
  socialValidLogo: {
    width: 120,
    position: "absolute",
    bottom: 0,
    right: 0,
  },

  "@media (max-width:500px)": {
    pageLeft: {
      borderRight: "none",
      height: "90vh",
    },
    pageRight: {
      height: "80vh",
      marginBottom: 100,
    },
    pageRightInner: {
      padding: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    socialValidLogo: {
      top: 20,
      right: 0,
    },
    reportFakeBtn: {
      height: 40,
    },
  },

  "@media (min-width:500px)": {
    homePage: {
      padding: [20, 60],
    },
  },

  "@media (min-width:1024px)": {
    headerText: {
      fontSize: "14pt",
    },
    planeIcon: {
      width: 50,
    },
  },
  "@media (min-width:1200px)": {
    homePage: {
      padding: [20, 80],
    },
    headerText: {
      fontSize: "16pt",
    },
    planeIcon: {
      width: 60,
    },
    title: {
      fontSize: "38pt",
    },
    socialIcon: { height: 150, width: 150 },
    searchRealTxt: {
      fontSize: "18pt",
    },
    playIcon: {
      width: 50,
    },
    reportFakeBtn: {
      width: 190,
      fontSize: "15pt",
    },
    socialValidLogo: {
      width: 150,
    },
    exploreBox: {
      height: 60,
      width: 250,
    },
    exploreTxt: {
      marginLeft: 30,
      fontSize: "26pt",
    },
  },
}));
