import { ReactComponent as FacebookIcon } from "assets/svgs/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/svgs/instagram.svg";
import { ReactComponent as TiktokIcon } from "assets/svgs/tiktok.svg";
import { ReactComponent as TwitterIcon } from "assets/svgs/twitter.svg";
import { ReactComponent as TinderIcon } from "assets/svgs/tinder.svg";
import { ReactComponent as CashappIcon } from "assets/svgs/cashapp.svg";
import { ReactComponent as PinterestIcon } from "assets/svgs/pinterest.svg";
import { ReactComponent as LinkedinIcon } from "assets/svgs/linkedin.svg";
import { ReactComponent as SnapchatIcon } from "assets/svgs/snapchat.svg";
import { ReactComponent as DiscordIcon } from "assets/svgs/discord.svg";
import { ReactComponent as RedditIcon } from "assets/svgs/reddit.svg";
import { ReactComponent as PaypalIcon } from "assets/svgs/paypal.svg";
import { ReactComponent as KikIcon } from "assets/svgs/kik.svg";
import { ReactComponent as VenmoIcon } from "assets/svgs/venmo.svg";
import { ReactComponent as PofIcon } from "assets/svgs/pof.svg";
import { ReactComponent as YoutubeIcon } from "assets/svgs/youtube.svg";
import { ReactComponent as OtherPlatfformsIcon } from "assets/svgs/other-platforms.svg";

export const Socials = [
  {
    name: "Facebook",
    icon: (props: any) => <FacebookIcon {...props} />,

    url: "https://www.facebook.com/help/174210519303259?helpref=faq_content",
  },
  {
    name: "Instagram",
    icon: (props: any) => <InstagramIcon {...props} />,

    url: "https://help.instagram.com/contact/636276399721841",
  },
  {
    name: "Tiktok",
    icon: (props: any) => <TiktokIcon {...props} />,

    url: "https://tiktokimpersonationusca.zendesk.com/hc/en-us/requests/new",
  },
  {
    name: "Twitter",
    icon: (props: any) => <TwitterIcon {...props} />,

    url: "https://help.twitter.com/en/safety-and-security/report-twitter-impersonation#how",
  },
  {
    name: "Tinder",
    icon: (props: any) => <TinderIcon {...props} />,

    url: "https://www.help.tinder.com/hc/en-us/requests/new?ticket_form_id=360000234472",
  },
  {
    name: "Cashapp",
    icon: (props: any) => <CashappIcon {...props} />,

    url: "https://cash.app/contact",
  },
  {
    name: "Pinterest",
    icon: (props: any) => <PinterestIcon {...props} />,

    url: "https://help.pinterest.com/en/article/report-impersonation",
  },
  {
    name: "Linkedin",
    icon: (props: any) => <LinkedinIcon {...props} />,

    url: "https://www.linkedin.com/help/linkedin/answer/a1338436/report-fake-profiles?lang=en",
  },
  {
    name: "Snapchat",
    icon: (props: any) => <SnapchatIcon {...props} />,

    url: "https://help.snapchat.com/hc/en-us/requests/new",
  },
  {
    name: "Discord",
    icon: (props: any) => <DiscordIcon {...props} />,

    url: "https://support.discord.com/hc/en-us/requests/new",
  },
  {
    name: "Reddit",
    icon: (props: any) => <RedditIcon {...props} />,

    url: "https://reddit.zendesk.com/hc/en-us/articles/360043479851-How-do-I-report-a-user-",
  },
  {
    name: "Paypal",
    icon: (props: any) => <PaypalIcon {...props} />,

    url: "https://www.paypal.com/us/cshelp/article/how-do-i-report-potential-fraud-spoof-or-unauthorized-transactions-to-paypal-help165",
  },
  {
    name: "Kik",
    icon: (props: any) => <KikIcon {...props} />,

    url: "https://kikhelpcenter.zendesk.com/hc/en-us/articles/4402344552731-How-do-I-report-impersonation-",
  },
  {
    name: "Venmo",
    icon: (props: any) => <VenmoIcon {...props} />,

    url: "https://help.venmo.com/hc/en-us/articles/4410324103187-Reporting-Fake-or-Suspicious-Emails-",
  },
  {
    name: "POF",
    icon: (props: any) => <PofIcon {...props} />,

    url: "https://help.pof.com/hc/en-us/articles/360061102731-Reporting-a-profile-photo-or-message-on-POF",
  },
  {
    name: "YouTube",
    icon: (props: any) => <YoutubeIcon {...props} />,

    url: "https://www.youtube.com/t/contact_us",
  },
  // {
  //   name: "Other",
  //   icon: (props: any) => <OtherPlatfformsIcon {...props} />,

  //   url: "",
  // }
];
