import React from "react";

interface FlexProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  justify?:
    | "center"
    | "space-between"
    | "space-around"
    | "flex-start"
    | "flex-end";
  align?: "center" | "baseline" | "flex-start" | "flex-end";
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  wrap?: "nowrap" | "wrap" | "unset";
  children?: React.ReactChild | React.ReactChild[];
}

export default function Flex({
  children,
  justify,
  align,
  direction,
  wrap,
  style = {},
  ...props
}: FlexProps) {
  return (
    <div
      {...props}
      style={{
        ...style,
        display: "flex",
        flexDirection: direction,
        alignItems: align,
        justifyContent: justify,
        flexWrap: wrap,
      }}
    >
      {children}
    </div>
  );
}
