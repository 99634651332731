import { Link } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { ModalProps, Typography } from "antd";

import Flex from "layouts/Flex";
import CustomModal from "layouts/CustomModal";

import { Socials } from "data/socials";

interface ContinueToLinkModalProps extends ModalProps {
  data?: any;
}

export default function ContinueToLinkModal({
  data,
  ...props
}: ContinueToLinkModalProps) {
  const classes = useStyle();

  if (!!!data) return <></>;

  return (
    <CustomModal {...props}>
      <Flex justify="center" align="center" direction="column">
        <Typography.Text className={classes.continueToLinkTxt}>
          Continue To Link
        </Typography.Text>
        <Typography.Text className={classes.accWasReportedTxt}>
          This account was reported Fake
        </Typography.Text>

        <Flex justify="center" className={classes.icon}>
          {Socials.find(
            (f) => f.name.toLowerCase() === data?.social_channel
          )?.icon({ width: 150, fill: "#FE7C3D" }) ?? <></>}
        </Flex>

        <Link to={data.url}>
          <Typography.Text className={classes.reportedAccLinkTxt}>
            {data.url}
          </Typography.Text>
        </Link>

        <Typography.Text className={classes.bottomText}>
          Data Provided by Social Valid- Peer reviewed
        </Typography.Text>
        <Typography.Text className={classes.bottomText}>
          Inaccurate? Submit a repeal.
        </Typography.Text>
      </Flex>
    </CustomModal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  icon: {
    "& path": {
      fill: "#FE7C3D !important",
    },
  },

  continueToLinkTxt: {
    lineHeight: "50px",
    fontSize: "30pt",
    fontFamily: "Arimo",
    fontWeight: 400,
    color: "#010101",
    display: "block",
    marginTop: 10,
  },
  accWasReportedTxt: {
    fontSize: "12pt",
    fontFamily: "Yaldevi",
    fontWeight: 400,
    color: "#000000",
    display: "block",
    marginBottom: 20,
  },
  reportedAccLinkTxt: {
    maxWidth: "90%",
    fontSize: "18pt",
    fontFamily: "Open Sans",
    textAlign: "center",
    fontWeight: 300,
    color: "#6271C5",
    display: "block",
    margin: [20, "auto", 40],
  },
  bottomText: {
    fontSize: "10pt",
    fontFamily: "Yaldevi",
    fontWeight: 400,
    color: "#000000",
  },
}));
