import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { 
  CloseOutlined, 
  SearchOutlined, 
  LoadingOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Grid,
  Input,
  MenuProps,
  message,
  Row,
  Spin,
  Switch,
} from "antd";

import Flex from "layouts/Flex";

import { ReactComponent as PlaneIcon } from "assets/svgs/cloud-and-plane.svg";
import { ReactComponent as PlaneIconDark } from "assets/svgs/cloud-and-plane-dark.svg";
import { ReactComponent as GreyPlusSignIcon } from "assets/svgs/grey-plus-sign.svg";
import { ReactComponent as ValidStampIcon } from "assets/svgs/valid-stamp.svg";
import HalfMoonIcon from "assets/pngs/half-moon.png";

import SocialValidLogo from "assets/pngs/Powered-by-Social-Valid.png";
import ReportFakeModal from "components/modals/ReportFakeModal";
import ContinueToLinkValidModal from "components/modals/ContinueToLinkValidModal";
import ContinueToLinkModal from "components/modals/ContinueToLinkModal";
import { Socials } from "data/socials";
import ReportSocial from "components/others/ReportSocial";
import PlatformBox from "components/others/PlatformBox";
import { searchLinks } from "api/api";
import { isValidURL } from "shared/functions";
import ClaimAndVerifyModal from "components/modals/ClaimAndVerifyModal";
import moment from "moment";

const { useBreakpoint } = Grid;

export default function HomePage() {
  const classes = useStyle();
  const isMD = useBreakpoint().md;
  const isLG = useBreakpoint().lg && window.innerHeight < 1360;

  const [isReport, setIsReport] = useState(false);
  const [reportFakeModalOpen, setReportFakeModalOpen] = useState(false);
  const [reportedHandleModalOpen, setReportedHandleModalOpen] = useState(false);
  const [validHandleModalOpen, setValidHandleModalOpen] = useState(false);
  const [claimSocialsModalOpen, setClaimSocialsModalOpen] = useState(false);
  const [openedModalData, setOpenedModalData] = useState<any>(null);
  const [selectedSocial, setSelectedSocial] = useState<string | null>(null);

  const [input, setInput] = useState("")
  const [usernameInput, setUsernameInput] = useState("");
  const [linkInput, setLinkInput] = useState("");
  const [resultLinks, setResultLinks] = useState<any[]>([]);

  const [timerId, setTimerId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [showNoResultsMessage, setShowNoResultsMessage] = useState(false)

  const getData = useCallback(async (signal: AbortSignal) => {
    if (usernameInput === "") return;
    try {
      setIsLoading(true)
      setShowNoResultsMessage(true)
      const res = await searchLinks(usernameInput, signal); // Pass the signal to the searchLinks function
      // console.log(res);
      if (res[1] === usernameInput) {
        setIsLoading(false)
        if (res[0] === 429) {
          setShowNoResultsMessage(true)
        } else {
          setResultLinks(res[0]?.results);
          setShowNoResultsMessage(res[0]?.results.length === 0)
        }
      }
    } catch (error: any) {
      if (error.name === "AbortError") {
        // console.log(`getData aborted: ${error.message}`);
      } else {
        // console.log(`getData error: ${error.message}`);
      }
    }
  }, [usernameInput]);

  useEffect(() => {
    const controller = new AbortController(); // Create an AbortController instance
    getData(controller.signal); // Pass the signal to the getData function
    return () => {
      // console.log("abort")
      controller.abort(); // Abort the controller when the component unmounts
    };
  }, [getData]);

  const handleChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
    if (timerId) {
        clearTimeout(timerId);
    }
    setTimerId(
      setTimeout(() => {
        setUsernameInput(e.target.value)
        setTimerId(null);
      }, 500)
    );
  }

  const getDifference = (date: any) => {
    let daysAgo = +moment().diff(date, "days");
    if (daysAgo > 0) {
      return daysAgo + " days ago";
    } else {
      return "today";
    }
  };

  const handleClaimSocialsClick = () => {
    setClaimSocialsModalOpen(true);
  };

  const handleReportFakeClick = () => {
    if (!isValidURL(linkInput) || linkInput === "")
      return message.error("Please enter a valid URL!");

    setReportFakeModalOpen(true);
    setOpenedModalData({ url: linkInput });
  };

  const handleReportedHandleClick = (data: any) => () => {
    setReportedHandleModalOpen(true);
    setOpenedModalData(data);
  };

  const handleValidHandleClick = (data: any) => () => {
    setValidHandleModalOpen(true);
    setOpenedModalData(data);
  };

  const loadingItem: MenuProps["items"] = [{
    key: "1234567",
    icon: <Spin indicator={<LoadingOutlined style={{ width: 28 }}/>}/>,
    label: (
      <Flex
        justify="space-between"
        className={classes.dropdownItemContent}
      >
        <span className={classes.dropdownItemContentMainText}>
          Searching...
        </span>
      </Flex>
    )
  }]

  const noResultsItems: MenuProps["items"] = [{
    key: "1234567",
    icon: <ExclamationCircleOutlined style={{ width: 28 }}/>,
    label: (
      <Flex
        justify="space-between"
        className={classes.dropdownItemContent}
      >
        <span className={classes.dropdownItemContentMainText}>
          No results found
        </span>
      </Flex>
    )
    },
    {
      key: "A",
      icon: <GreyPlusSignIcon style={{ width: 28 }} />,
      label: (
        <Flex
          justify="space-between"
          className={classes.dropdownItemContent}
          onClick={handleClaimSocialsClick}
        >
          <span
            className={classes.dropdownItemContentMainText}
            style={{ color: "#A6A6A6" }}
          >
            claim your socials
          </span>
          <span className={classes.dropdownItemContentStatus}>
            Pending Setup
          </span>
        </Flex>
      ),
    },
  ]

  const items: MenuProps["items"] = [
    ...resultLinks
      .filter((i) => !!i.handle)
      .map((o, i) => ({
        key: i,
        icon: (
          <span
            className={
              o.is_known_valid ? classes.validIcon : classes.invalidIcon
            }
          >
          {(
            Socials.find((f) => f.name.toLowerCase() === o.social_channel) 
              ?? Socials.find((f) => f.name === "Other")
            )?.icon({ width: 30 })}
          </span>
        ),
        label: (
          <Flex
            justify="space-between"
            className={classes.dropdownItemContent}
            onClick={
              o.is_known_valid
                ? handleValidHandleClick(o)
                : handleReportedHandleClick(o)
            }
          >
            <span className={classes.dropdownItemContentMainText}>
              @{o.handle}
            </span>
            <span className={classes.dropdownItemContentStatus}>
              {o.is_known_valid && <ValidStampIcon width={75} />}
              {!!o?.most_recent_report && (
                <>
                  Reported{" "}
                  <b
                    style={{
                      paddingLeft: 3,
                    }}
                  >
                    {getDifference(o.most_recent_report.$date)}
                  </b>
                </>
              )}
            </span>
          </Flex>
        ),
      })),

    {
      key: "A",
      icon: <GreyPlusSignIcon style={{ width: 28 }} />,
      label: (
        <Flex
          justify="space-between"
          className={classes.dropdownItemContent}
          onClick={handleClaimSocialsClick}
        >
          <span
            className={classes.dropdownItemContentMainText}
            style={{ color: "#A6A6A6" }}
          >
            claim your socials
          </span>
          <span className={classes.dropdownItemContentStatus}>
            Pending Setup
          </span>
        </Flex>
      ),
    },
  ];

  if (selectedSocial) {
    return (
      <ReportSocial
        social={selectedSocial}
        onClose={() => setSelectedSocial(null)}
      />
    );
  }

  const handleClickX = (e: any) => {
    setInput("");
    setResultLinks([]);
    setIsLoading(false)
    e.stopPropagation();
    if (usernameInput == "") {
      setIsOpen(false)
      setShowNoResultsMessage(false)
    } else {
      setIsOpen(true);
      setShowNoResultsMessage(false)
    }
    setUsernameInput(""); 
  }

  return (
    <div 
      className={classes.homePage} 
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <ReportFakeModal
        data={openedModalData}
        open={reportFakeModalOpen}
        onDone={() => {
          setReportFakeModalOpen(false);
          setOpenedModalData(null);
          setLinkInput("");
        }}
        onCancel={() => {
          setReportFakeModalOpen(false);
          setOpenedModalData(null);
        }}
      />
      <ContinueToLinkValidModal
        data={openedModalData}
        open={validHandleModalOpen}
        onCancel={() => {
          setValidHandleModalOpen(false);
          setOpenedModalData(null);
        }}
      />
      <ContinueToLinkModal
        data={openedModalData}
        open={reportedHandleModalOpen}
        onCancel={() => {
          setReportedHandleModalOpen(false);
          setOpenedModalData(null);
        }}
      />
      <ClaimAndVerifyModal
        open={claimSocialsModalOpen}
        onCancel={() => {
          setClaimSocialsModalOpen(false);
        }}
      />

      <Flex align="center" className={classes.header}>
        <span className={classes.headerText}>
          CATFISH & FAKE
          <br /> ACCOUNT DATABASE
        </span>

        <PlaneIcon className={classes.planeIcon} />
      </Flex>

      <Row className={classes.container}>
        <Col
          xs={24}
          sm={18}
          {...(window.innerHeight < 1360 ? { lg: 14, xxl: 20 } : {})}
          className={classes.pageLeft}
        >
          <Flex
            direction="column"
            justify={isLG ? "space-between" : "flex-start"}
            className={classes.contentWrapper}
          >
            <div>
              <span className={classes.mainText}>
                Report Scammers,
                {window.innerHeight < 550 ? " " : <br />}
                Fake Accounts, and Impersonators
              </span>

              <Flex align="center" className={classes.switchWrapper}>
                <span className={classes.switchText}>Search</span>
                <Switch
                  className={classes.modeSwitch}
                  checked={isReport}
                  onChange={setIsReport}
                />
                <span className={classes.switchText}>Report</span>
              </Flex>

              <div hidden={!isReport}>
                <Input
                  size="large"
                  hidden={!isReport}
                  placeholder="Enter Link"
                  value={linkInput}
                  prefix={<PlaneIconDark style={{ width: 40 }} />}
                  suffix={
                    <Button
                      type="primary"
                      shape="round"
                      className={classes.reportFakeBtn}
                      onClick={handleReportFakeClick}
                    >
                      {isMD ? "Report Fake" : "Report"}
                    </Button>
                  }
                  onChange={(e) => setLinkInput(e.target.value)}
                  className={classes.searchInput}
                />
              </div>

              <div hidden={isReport}>
                <Dropdown 
                  menu={{
                    items: isLoading ? loadingItem : (
                      showNoResultsMessage ? noResultsItems : items
                    )
                  }}
                  open={isOpen}
                >
                  <Input
                    id="select-input"
                    size="large"
                    hidden={isReport}
                    value={input}
                    placeholder="Enter @handle"
                    className={classes.searchInput}
                    prefix={
                      <SearchOutlined 
                        onClick={(e) => {
                          setIsOpen(true);
                          e.stopPropagation();}}
                      />
                    }
                    suffix={
                      <CloseOutlined onClick={handleClickX} />
                    }
                    onChange={handleChangeUsername}
                    onClick={(e) => {
                      setIsOpen(true);
                      e.stopPropagation();
                    }}
                  />
                </Dropdown>
              </div>

              <span className={classes.searchInputSupportText}>
                Search or report a fake account, use social media handle
                username Option to use to search matching catfish images (Coming
                soon)
              </span>
            </div>

            <PlatformBox onSelect={setSelectedSocial} />
          </Flex>
        </Col>

        <Col
          xs={24}
          sm={6}
          {...(window.innerHeight < 1360 ? { lg: 10, xxl: 4 } : {})}
          style={{ position: "relative" }}
        >
          <Flex
            justify="center"
            align="center"
            className={classes.contentWrapper}
          >
            <img
              src={HalfMoonIcon}
              alt="half-moon"
              className={classes.halfMoonIcon}
            />
          </Flex>
        </Col>
        <img
          src={SocialValidLogo}
          onClick={
            () => window.open(`${process.env.REACT_APP_API_HOST}/home/`, '_blank')
          }
          alt="Powered by Social Valid"
          className={classes.socialValidLogo}
        />
      </Row>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  "@global": {
    ".ant-switch .ant-switch-handle": {
      // height: 22,
      // width: 22,
      top: "5px !important",
      insetInlineStart: "6px !important",
    },
    ".ant-switch.ant-switch-checked .ant-switch-handle": {
      insetInlineStart: "calc(100% - 25px) !important",
    },
  },
  homePage: {
    padding: [20, 30],

    background: colors.dark1000,
    minHeight: "100vh",
  },
  header: {
    paddingBottom: 20,
    borderBottom: "0.5px solid #f8f8f8",
  },
  headerText: {
    marginRight: 20,
    color: colors.light100,
    // fontSize: "16pt",
    fontFamily: "Arimo",
    fontWeight: 700,
  },
  planeIcon: {
    width: 40,
  },
  container: {
    height: "85vh",
    position: "relative",
    flexDirection: "column-reverse",
  },
  pageLeft: {
    height: "60vh",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    top: 0,
    left: 0,
    zIndex: 2,
  },
  contentWrapper: {},
  mainText: {
    display: "block",
    color: colors.light100,
    fontSize: "28pt",
    fontFamily: "Arimo",
    lineHeight: "45px",
  },
  switchWrapper: {
    margin: [30, 0, 20],
  },
  switchText: {
    color: colors.light100,
    fontFamily: "Yaldevi",
    fontWeight: 400,
    fontSize: "12pt",
  },
  modeSwitch: {
    height: 28,
    width: 60,
    margin: [0, 10],
    backgroundColor: "#5E17EB !important",
  },
  searchInput: {
    height: 50,
    maxWidth: 550,

    "& .anticon": {
      fontSize: 28,
      color: colors.dark1000,
      "&:last-child": {
        fontSize: 20,
      },
    },
    "& input": {
      paddingLeft: "10px !important",
      color: colors.dark1000,
      fontFamily: "'Fredoka One'",
      fontSize: "16pt",
      fontWeight: 400,
    },
  },
  searchInputSupportText: {
    marginTop: 15,
    maxWidth: 550,
    fontWeight: 400,
    fontSize: "10pt",
    fontFamily: "Yaldevi",
    color: colors.light500,
    display: "block",
  },

  socialValidLogo: {
    width: 120,
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  halfMoonIcon: {
    width: 400,
    height: 400,
  },
  reportFakeBtn: {
    width: 180,

    fontFamily: "Yaldevi",
    fontWeight: 400,
    fontSize: "14pt",
    background: "rgb(240,103,125) !important",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  validIcon: {
    "& path": {
      fill: "#19B650 !important",
    },
  },
  invalidIcon: {
    "& path": {
      fill: "#FE7C3D !important",
    },
  },
  dropdownItemContent: {
    paddingLeft: 15,
    color: colors.dark1000,
    fontSize: "12pt",
  },
  dropdownItemContentMainText: {
    fontFamily: "'Fredoka One'",
    fontWeight: 400,
  },
  dropdownItemContentStatus: {
    fontFamily: "Yaldevi",
    fontWeight: 400,
    fontSize: "10pt",
    color: "#545454",
    "& > b": {
      fontSize: "12pt",
    },
  },

  "@media (max-height:680px) and (max-width:500px)": {
    mainText: {
      marginTop: "15vh !important",
      marginBottom: "0px !important",
    },
  },
  "@media (max-height:680px) and (min-width:500px) and (max-width:1440px)": {
    mainText: {
      fontSize: "30pt !important",
      lineHeight: "45px !important",
      paddingTop: "50px !important",
    },
  },
  "@media (max-width:500px)": {
    mainText: {
      marginTop: "20vh",
      marginBottom: 100,
    },
    pageLeft: {
      height: "70vh",
    },
    reportFakeBtn: {
      width: 80,
      fontSize: "12pt",
    },
    dropdownItemContent: {
      paddingLeft: 5,
    },
    dropdownItemContentStatus: {
      fontSize: "10pt",
      "& > b": {
        fontSize: "10pt",
      },
    },
  },
  "@media (min-width:500px)": {
    homePage: {
      padding: [20, 60],
      overflow: "hidden",
    },
    contentWrapper: {
      height: "calc(100vh - 120px)",
    },

    mainText: {
      paddingTop: 30,
      fontSize: "3rem",
      lineHeight: "55px",
    },

    container: {
      height: "unset",
      flexDirection: "unset",
    },
    pageLeft: {
      height: "unset",
      position: "unset",
      display: "unset",
    },
    halfMoonIcon: {
      height: "unset",
      width: "80vw",
      position: "absolute",
    },
    headerText: {
      fontSize: "14pt",
    },
    planeIcon: {
      width: 50,
    },
  },

  "@media (min-width:1024px) and (max-height: 1360px)": {
    halfMoonIcon: {
      width: "100%",
      position: "unset",
    },
  },
  "@media (min-width:1200px) and (max-height: 1360px)": {
    homePage: {
      padding: [20, 80],
    },
    mainText: {
      paddingTop: 70,
      fontSize: "48pt",
      lineHeight: "unset",
    },
    headerText: {
      fontSize: "16pt",
    },
    planeIcon: {
      width: 60,
    },
    switchText: {
      fontSize: "15pt",
    },
    modeSwitch: {
      height: 28,
      width: 70,
    },
    switchWrapper: {
      margin: [40, 0, 30],
    },
    searchInputSupportText: {
      marginTop: 20,
      fontSize: "14pt",
    },
    platformBoxText: {
      fontSize: "12pt",
      lineHeight: "25px",
    },
    platformBox: {
      height: 70,
      padding: [0, 15],
    },
    socialValidLogo: {
      width: 150,
    },
    socialIcon: {
      height: 50,
      width: 50,
    },
    playIcon: {
      width: 50,
    },
    halfMoonIcon: {
      height: "unset",
      width: "100%",
      position: "unset",
    },
  },
}));
