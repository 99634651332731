import { Link } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { ModalProps, Typography } from "antd";

import Flex from "layouts/Flex";
import CustomModal from "layouts/CustomModal";

import NewVideoGif from "assets/gifs/new-video.gif";
import VideoPlayer from "components/others/VideoPlayer";
import { useRef } from "react";

interface ClaimAndVerifyModalProps extends ModalProps {
  data?: any;
}

export default function ClaimAndVerifyModal({
  data,
  ...props
}: ClaimAndVerifyModalProps) {
  const videoPlayerRef = useRef(null);

  const classes = useStyle();

  return (
    <CustomModal {...props}>
      <Flex justify="center" align="center" direction="column">
        <Typography.Text className={classes.continueToLinkTxt}>
          Claim & Verify
        </Typography.Text>
        <Typography.Text className={classes.accWasReportedTxt}>
          Verify your Identity & Connect
          <br />
          All Your Web Properties with Social Valid
        </Typography.Text>

        <VideoPlayer
          videoUrl="https://cdn.jwplayer.com/manifests/8SVc4A1j.m3u8"
          playerRef={videoPlayerRef}
        />
        <Link to={process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : ""}>
          <Typography.Text className={classes.reportedAccLinkTxt} >
            Explore
          </Typography.Text>
        </Link>
      </Flex>
    </CustomModal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  continueToLinkTxt: {
    lineHeight: "50px",
    fontSize: "30pt",
    fontFamily: "Arimo",
    fontWeight: 500,
    color: "#010101",
  },
  accWasReportedTxt: {
    fontSize: "12pt",
    fontFamily: "Yaldevi",
    fontWeight: 400,
    color: "#000000",
    display: "block",
    marginBottom: 50,
    textAlign: "center",
  },
  reportedAccLinkTxt: {
    fontSize: "18pt",
    fontFamily: "Open Sans",
    fontWeight: 300,
    color: "#6271C5",
    display: "block",
    marginTop: 100,
  },
}));
